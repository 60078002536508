/* global require */
require.context('../images', true);

import flatpickr from 'flatpickr';
import { Hungarian } from 'flatpickr/dist/l10n/hu';

import Turbolinks from 'turbolinks';
Turbolinks.start();

import Rails from '@rails/ujs';
Rails.start();

import { slideUp, slideDown, createElementFromString } from '../utils';
import { progress, dropdown, toggleVisibility } from '../views';
import UserOptions from '../useroptions';

import Filters from '../filters';
Filters.start();

import Table from '../table';
Table.init();

import 'ckeditor_loader';

import '../stylesheets/application.scss';

document.addEventListener('turbolinks:load', function() {
  window.addEventListener('popstate', () => {
    Turbolinks.visit(window.location.toString(), { action: 'replace' });
  });

  slideDown();
  slideUp(4000);

  if (gon.current_user_id) {
    // general initialization on page load, if user is logged in
    
    progress();

    window.Options = new UserOptions(gon.current_user_id, gon.user_options);
  }

  flatpickr('.oms-date-field', {
    'locale': Hungarian,
    weekNumbers: true,
    dateFormat: 'Y/m/d'
  });

  flatpickr('.oms-time-field', {
    'locale': Hungarian,
    noCalendar: true,
    enableTime: true,
    time_24hr: true
  });

  flatpickr('.oms-datetime-field', {
    'locale': Hungarian,
    enableTime: true,
    weekNumbers: true,
    dateFormat: 'Y/m/d H:i'
  });

  document.querySelectorAll('.oms-with-clear').forEach(item => {
    item.appendChild(createElementFromString('<span class="oms-clear"></span>'));
    item.addEventListener('click', event => {
      event.currentTarget.children[0]._flatpickr.clear();
    });
  });

  dropdown();
  toggleVisibility();

  // then the view-specific stuff
  let controller = document.querySelector('body').dataset.controller;
  let action = document.querySelector('body').dataset.action;
  import('../views/' + controller).then(module => {
    (typeof module['init' + action] === 'function') ? module['init' + action]() : null;
    Table.start();
  });
});